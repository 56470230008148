import { gql } from '__generated__';

export default gql(`
  query GetCharity($id: ID!) {
    charity(id: $id) {
      id
      title
      logo_url
      description
    }
  }
`);
