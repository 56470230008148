import { Clock } from 'react-feather';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import UI from '../../common/UI';

export interface TimerProps {
  secondsLeft: number;
  children?: ReactNode;
}

const Timer = ({
  secondsLeft, children,
}: TimerProps) => {
  const { t } = useTranslation();

  const [timeLeft, setTimeLeft] = useState(secondsLeft);
  const [startTime, setStartTime] = useState(new Date());

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = window.setTimeout(() => {
        // Calculate the time left based on the total time that has elapsed to avoid clock drift.
        const timeElapsed = Math.floor(((new Date()).getTime() - startTime.getTime()) / 1000);
        const newTimeLeft = Math.max(0, secondsLeft - timeElapsed);
        setTimeLeft(newTimeLeft);
      }, 1000);

      return () => window.clearTimeout(timer);
    }

    return undefined;
  }, [timeLeft, startTime, secondsLeft]);

  useEffect(() => {
    setStartTime(new Date());
    setTimeLeft(secondsLeft);
  }, [secondsLeft]);

  return (
    <UI.Div sc={{ background: 'gray.50' }}>
      {children}
      {' '}
      <UI.Span sc={{ noWrap: true, fontWeight: 500 }}>
        <UI.Icon>
          <Clock />
        </UI.Icon>
        {' '}
        {timeLeft > 60 ? t('n_minutes', { count: Math.ceil(timeLeft / 60) }) : t('n_seconds', { count: timeLeft })}
      </UI.Span>
    </UI.Div>
  );
};

export default Timer;
