import { createContext } from 'react';

import {
  BusinessRegistrationInput, CompleteCheckoutInput, CreateRegistrationInput, CreateUpgradeInput, InvoiceDetailsInput,
  ParticipantFieldEntryInput, ParticipantInput, PaymentMethodInput, TeamInput,
} from '__generated__/graphql';
import { Charity } from '../../common/Fundraising/CharityPicker';
import { Event, Product, Session } from './helpers';
import { TouchStatus } from '../../common/useTouchState';
import { Update } from '../../common/helpers';

export interface StateContextValue {
  form: CompleteCheckoutInput;
  session: Session;
  /** All promotion IDs that are present in the 'create' and 'business' arrays */
  promotionIds: string[];
  setQueueToken: (queueToken: string | null) => void;
  setRegistrations: (
    update: Update<CreateRegistrationInput[]>,
    event: Event,
  ) => void;
  cleanState: (event: Event) => void;
  setBusinessRegistrations: (
    update: Update<BusinessRegistrationInput[]>,
    event: Event,
  ) => void;
  setTicketUpgrades: (
    update: Update<CreateUpgradeInput[]>,
    registrationIndex: number,
    event: Event,
  ) => void;
  setStandAloneUpgrades: (
    (
      update: (upgrades: CreateUpgradeInput[]) => CreateUpgradeInput[],
      event: Event,
    ) => void
  );
  rememberDonationChoice: (product: Product, donate?: boolean) => void;
  rememberCharity: (charity: Charity) => void;
  editParticipant: (update: Update<ParticipantInput>) => void;
  editRegistration: (
    update: Update<CreateRegistrationInput>,
    registrationIndex: number,
    event: Event,
  ) => void;
  editTicketUpgrade: (
    update: Update<CreateUpgradeInput>,
    registrationIndex: number,
    upgradeIndex: number,
  ) => void;
  editBusinessRegistration: (
    update: Update<BusinessRegistrationInput>,
    registrationIndex: number,
  ) => void;
  editStandAloneUpgrade: (
    update: Update<CreateUpgradeInput>,
    upgradeIndex: number,
  ) => void;
  editOrderField: (update: Update<ParticipantFieldEntryInput>, fieldIndex: number) => void;
  editTeam: (update: Update<TeamInput>) => void;
  setActiveRegistration: (index: number) => void;
  setCharity: (charity: Charity | null) => void;
  setInvitationCode: (invitationCode: string | null) => void;
  setCoupon: (coupon: string | null) => void;
  toggleInvoice: () => void;
  editInvoiceDetails: (update: Update<InvoiceDetailsInput>) => void;
  editPaymentMethod: (update: Update<PaymentMethodInput>) => void;
  toggleDownPayment: (enable: boolean) => void;
  toggleMailOptIn: (id: string) => void;
  toggleTerms: () => void;
  touch: (key: string) => void;
  untouch: (key: string) => void;
  touched: (key?: string) => TouchStatus;
}

const initialContext = {} as StateContextValue;

export default createContext<StateContextValue>(initialContext);
