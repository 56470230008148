import { User } from 'react-feather';
import { useTranslation } from 'react-i18next';
import useLocale from '../../common/useLocale';

import UI from '../../common/UI';
import useProject from '../Common/useProject';

const UserInfo = () => {
  const { t } = useTranslation();
  const { locale } = useLocale();
  const project = useProject();

  return (
    <UI.AButton
      href={`/p/${project.id}/login?locale=${locale}`}
      target="_blank"
      rel="noopener noreferrer"
      sc={{ blank: true, color: 'link', block: true }}
    >
      <UI.Icon>
        <User />
      </UI.Icon>
      {' '}
      {t('manage_registration')}
    </UI.AButton>
  );
};

export default UserInfo;
