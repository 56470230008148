import { useEffect, useRef } from 'react';
import flatten from 'lodash/flatten';
import startCase from 'lodash/startCase';

import { CompleteCheckoutInput } from '__generated__/graphql';

import { CheckoutStep, Queue, SuccessStep } from '../helpers';
import { PersonalisationInfo } from '../usePersonalisationInfo';
import { useTracking } from '../../../common/Tracking';
import useLocale from '../../../common/useLocale';

interface UseCheckoutStepTrackingProps {
  form: CompleteCheckoutInput;
  step: CheckoutStep | typeof SuccessStep;
  stepIndex: number;
  valid: boolean[];
  personalisation: PersonalisationInfo;
  embedded: boolean;
  embedOpen: boolean;
  queue: Queue | null;
}

const useCheckoutTracking = ({
  form, step, stepIndex, valid, personalisation, embedded, embedOpen, queue,
}: UseCheckoutStepTrackingProps) => {
  const { track } = useTracking();

  const widgetOpenedRef = useRef(false);

  useEffect(() => {
    if (embedded && embedOpen !== widgetOpenedRef.current) {
      track?.(`Checkout:${embedOpen ? 'WidgetOpened' : 'WidgetClosed'}`);
      widgetOpenedRef.current = embedOpen;
    }
  }, [embedded, embedOpen, track]);

  useEffect(() => {
    if (queue) {
      track?.('Checkout:QueueEntered');
    }
  }, [track, queue]);

  useEffect(() => {
    if (!queue) {
      track?.(`Checkout:StepOpened:${formatName(step)}`);
    }
  }, [track, step, queue]);

  const emailEnteredRef = useRef(false);

  useEffect(() => {
    if (!emailEnteredRef.current && form.participant.email) {
      emailEnteredRef.current = true;
      track?.('Checkout:EmailEntered');
    }
  });

  const registrationCountRef = useRef(0);
  const productCountRef = useRef(0);

  useEffect(() => {
    const registrationCount = form.registrations.create.length;
    const productCount = flatten(
      form.registrations.create.map(({ upgrades }) => upgrades),
    ).length;

    if (registrationCount > registrationCountRef.current) {
      track?.('Checkout:TicketAdded');
    }

    if (registrationCount < registrationCountRef.current) {
      track?.('Checkout:TicketRemoved');
    }

    if (productCount > productCountRef.current) {
      track?.('Checkout:ProductAdded');
    }

    if (productCount < productCountRef.current) {
      track?.('Checkout:ProductRemoved');
    }

    registrationCountRef.current = registrationCount;
    productCountRef.current = productCount;
  });

  const stepCompletedRef = useRef<{ [step: string]: boolean; }>({});

  useEffect(() => {
    if (!stepCompletedRef.current[step] && valid[stepIndex]) {
      // Small exception: the personalisation step is only valid if all registrations have been personalised
      if (step !== CheckoutStep.Personalisation
        || personalisation.nextIndex >= personalisation.personalisableRegistrations.length) {
        track?.(`Checkout:StepCompleted:${formatName(step)}`);
        stepCompletedRef.current[step] = true;
      }
    }
  });

  // Track changing the language
  const { locale } = useLocale();
  const localeRef = useRef(locale);

  useEffect(() => {
    if (locale !== localeRef.current) {
      track?.('Checkout:LanguageChanged', { locale });
    }

    localeRef.current = locale;
  });
};

const formatName = (name: string) => startCase(name).replace(/\s+/g, '');

export default useCheckoutTracking;
