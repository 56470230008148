import { ArrowUpRight } from 'react-feather';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { EmbedContext } from '../../Common/EmbedProvider';
import { containsKeyPrefix, filterServerErrors } from '../../../common/helpers';
import { getTicketsForSale, getVisibleProducts, getVisibleProductsForTicket } from '../helpers';
import { useEvent } from '../EventProvider';
import BusinessProductSelector from './BusinessProductSelector';
import CheckoutContext from '../CheckoutContext';
import StandAloneProductSelector from './StandAloneProductSelector';
import UI from '../../../common/UI';

/**
 * Form for business and stand-alone upgrades.
 */
const ExtrasForm = () => {
  const { t } = useTranslation();

  const { event } = useEvent();

  const {
    form,
    standAloneProducts,
    quantities,
    validators: {
      extras: {
        errors,
      },
    },
    touch,
  } = useContext(CheckoutContext);

  const businessTickets = getTicketsForSale(event).filter(
    (ticket) => ticket.business && quantities.tickets[ticket.id] > 0
      && getVisibleProductsForTicket(ticket, event.products_for_sale).length > 0,
  );

  const visibleStandAloneProducts = getVisibleProducts(standAloneProducts);

  const clientErrors = filterServerErrors(errors);
  const valid = !containsKeyPrefix(clientErrors, 'registrations.business')
    && !containsKeyPrefix(clientErrors, 'registrations.stand_alone_upgrades');

  const requiredUpgradeError = Object.values(errors || {})
    .filter((error) => error.required_upgrades)[0]?.required_upgrades;

  const donationError = Object.values(errors || {}).filter((error) => error.indicate_donation)[0]?.indicate_donation
      || errors['registrations.stand_alone_upgrades']?.indicate_donation;

  const hasUpgradeFieldErrors = form.registrations.stand_alone_upgrades.filter(
    (upgrade, index) => containsKeyPrefix(errors, `registrations.stand_alone_upgrades.${index}`),
  ).length > 0;

  const { scrollToSection, scrollTop } = useContext(EmbedContext);

  const scrollToErrorFields = () => {
    if (hasUpgradeFieldErrors) {
      scrollToSection('UpgradeInfoForm_0');
    } else {
      scrollTop();
    }

    Object.keys(errors).forEach((key: string) => touch(key));
  };

  return (
    <UI.FadeIn>
      <UI.FormGrid sc={{ separatorV: true }}>
        {businessTickets.map((ticket) => (
          <BusinessProductSelector ticket={ticket} key={ticket.id} />
        ))}
        {visibleStandAloneProducts.length > 0 && (
          <StandAloneProductSelector products={visibleStandAloneProducts} />
        )}
        {!valid && (
          <UI.FadeIn>
            <UI.Warning>
              <UI.FormGrid sc={{ gutter: 0.25 }}>
                {requiredUpgradeError && (
                  <UI.Div>
                    <UI.A
                      onClick={() => scrollToSection(`ProductSelect_${requiredUpgradeError.products[0].id}`)}
                      sc={{ secondary: true }}
                      style={{ fontWeight: 500 }}
                      role="button"
                    >
                      {t('select_required_products')}
                      {' '}
                      <UI.Icon>
                        <ArrowUpRight />
                      </UI.Icon>
                    </UI.A>
                  </UI.Div>
                )}
                {donationError && (
                  <UI.Div>
                    <UI.A
                      onClick={() => scrollToSection(`ProductSelect_${donationError.products[0].id}`)}
                      sc={{ secondary: true }}
                      style={{ fontWeight: 500 }}
                      role="button"
                    >
                      {t('indicate_donation')}
                      {' '}
                      <UI.Icon>
                        <ArrowUpRight />
                      </UI.Icon>
                    </UI.A>
                  </UI.Div>
                )}
                {hasUpgradeFieldErrors && (
                  <UI.Div>
                    <UI.A
                      onClick={() => scrollToErrorFields()}
                      sc={{ secondary: true }}
                      style={{ fontWeight: 500 }}
                      role="button"
                    >
                      {t('fill_required_fields')}
                      {' '}
                      <UI.Icon>
                        <ArrowUpRight />
                      </UI.Icon>
                    </UI.A>
                  </UI.Div>
                )}
              </UI.FormGrid>
            </UI.Warning>
          </UI.FadeIn>
        )}
      </UI.FormGrid>
    </UI.FadeIn>
  );
};

export default ExtrasForm;
