import { useTranslation } from 'react-i18next';

import { useCheckoutSummary } from './CheckoutSummaryProvider';
import Timer from '../Common/Timer';

const CheckoutTimers = () => {
  const { t } = useTranslation();
  const { checkoutSummary } = useCheckoutSummary();

  const hasTickets = checkoutSummary.tickets.length > 0;

  return (
    <Timer secondsLeft={checkoutSummary.time_left || 0}>
      {hasTickets ? t('time_left_to_finish_registration') : t('time_left_to_select_tickets')}
    </Timer>
  );
};

export default CheckoutTimers;
