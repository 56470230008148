import { Component, ReactNode } from 'react';
import Rollbar from 'rollbar/src/browser/core';

import { inProductionEnvironment } from '../../common/helpers';
import { useRollbar } from '../../common/Common/RollbarProvider';

interface ErrorBoundaryProps {
  redirect: string;
  rollbar?: Rollbar;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { hasError: false };
  }

  componentDidCatch(error: any) {
    const { rollbar, redirect } = this.props;

    rollbar?.error(error);

    if (inProductionEnvironment()) {
      if (window.location.href !== redirect) {
        window.setTimeout(() => {
          // Redirect after a small delay, to minimize the chance the Rollbar request gets cancelled
          window.location.href = redirect;
        }, 250);
      }
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;

    if (hasError) {
      return <></>;
    }

    const { children } = this.props;

    return children;
  }
}

const ErrorBoundaryWrapper = ({ children, ...props }: ErrorBoundaryProps) => {
  const rollbar = useRollbar();

  return (
    <ErrorBoundary rollbar={rollbar} {...props}>
      {children}
    </ErrorBoundary>
  );
};

export default ErrorBoundaryWrapper;
