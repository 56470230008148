import { gql } from '__generated__';

export default gql(`
  mutation CompleteCheckout($input: CompleteCheckoutInput!) {
    completeCheckout(input: $input) {
      status
      redirect_url
    }
  }
`);
