import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import startCase from 'lodash/startCase';

import { TeamInput } from '__generated__/graphql';
import { getServerErrors } from '../../../common/helpers';
import { useEvent } from '../EventProvider';
import { useTracking } from '../../../common/Tracking';
import CheckoutContext from '../CheckoutContext';
import TeamPicker, { TeamMode } from '../../../common/Teams/TeamPicker';
import UI from '../../../common/UI';

const TeamForm = () => {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { event } = useEvent();

  const {
    form,
    editTeam,
    allowIndividuals,
    allowCreateTeam,
    allowJoinTeam,
    error,
    touched,
    touch,
    untouch,
  } = useContext(CheckoutContext);

  const teamModeRef = useRef<TeamMode>();

  const handleChange = (team: TeamInput | null, mode: TeamMode) => {
    editTeam(() => team);

    if (mode !== teamModeRef.current) {
      track?.(`Checkout:TeamModeSelected:${startCase(mode)}`);
      teamModeRef.current = mode;
    }
  };

  const teamErrors = getServerErrors(error, 'team');

  return (
    <UI.FadeIn>
      <UI.FormGrid>
        <UI.Legend>{t('team')}</UI.Legend>
        <TeamPicker
          team={form.team}
          eventId={event.id}
          allowIndividuals={allowIndividuals}
          allowCreateTeam={allowCreateTeam}
          allowJoinTeam={allowJoinTeam}
          onChange={handleChange}
          touched={{ title: !!touched('team.title'), search: !!touched('team.search') }}
          touch={(field: string) => touch(`team.${field}`)}
          untouch={(field?: string) => (field ? untouch(`team.${field}`) : untouch('team'))}
          errors={teamErrors}
        />
      </UI.FormGrid>
    </UI.FadeIn>
  );
};

export default TeamForm;
