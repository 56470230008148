import { Route, Switch } from 'react-router-dom';
import { useContext, useEffect } from 'react';

import { EmbedContext } from '../Common/EmbedProvider';
import { ProjectProvider } from '../../common/useProject';
import { isSupportedLocale } from '../../i18n';
import { isValidLocale } from '../../common/helpers';
import { useBrandColor } from '../../common/Common/ThemeProvider';
import BuyRegistrationPage from './BuyRegistrationPage';
import EventNotFound from '../../common/EventNotFound';
import GetResaleLayoutQuery from './GetResaleLayoutQuery';
import Layout from '../Common/Layout';
import LocaleProvider from '../../common/LocaleProvider';
import OverviewPage from './OverviewPage';
import PageLoader from '../Common/PageLoader';
import WidgetContainer from '../Common/WidgetContainer';
import usePersistentThrottler from './usePersistentThrottler';
import useQuery from '../../api/useQuery';
import useUrlState from '../../common/useUrlState';

interface MainProps {
  eventId: string;
}

const Main = ({ eventId }: MainProps) => {
  const { embedded, open } = useContext(EmbedContext);

  const [urlState] = useUrlState({
    parse: (params: any) => ({
      locale: isValidLocale(params.locale) && isSupportedLocale(params.locale) ? params.locale : null,
    }),
  });

  const [throttled] = usePersistentThrottler({
    key: 'overview',
  });

  const { data, refetch, error } = useQuery(
    GetResaleLayoutQuery,
    {
      variables: {
        id: eventId,
      },
      skip: throttled,
    },
  );

  // Fetch the latest data when the embed is (re)opened
  useEffect(() => {
    if (embedded && open) {
      refetch();
    }
  }, [embedded, open, refetch]);

  const event = data?.event;

  const themeInitialized = useBrandColor(event?.brand_color, !!event);

  return (
    <LocaleProvider
      locale={urlState.locale || event?.locale}
      localizeRequests
    >
      <WidgetContainer>
        {!event && !error && (
          <PageLoader throttled={throttled} />
        )}

        {themeInitialized && event && !error && (
          <ProjectProvider project={event.project}>
            <Layout event={event} embedded={embedded}>
              <Switch>
                <Route
                  path="/:eventId/resale/:registrationId/:resaleToken"
                  render={({ match: { params: { registrationId, resaleToken } } }) => (
                    <BuyRegistrationPage
                      event={event}
                      registrationId={registrationId}
                      resaleToken={resaleToken}
                    />
                  )}
                />
                <Route
                  path="/:eventId/resale"
                  render={() => (
                    <OverviewPage event={event} />
                  )}
                />
              </Switch>
            </Layout>
          </ProjectProvider>
        )}

        {error && (
          <EventNotFound />
        )}
      </WidgetContainer>
    </LocaleProvider>
  );
};

export default Main;
