import { ObservableQuery } from '@apollo/client';
import { ReactNode, createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  GetEventQuery as GetEvent,
  GetEventQueryVariables as GetEventVariables,
} from '__generated__/graphql';

import { EmbedContext } from '../Common/EmbedProvider';
import { Event, TicketCategory } from './helpers';
import { useBrandColor } from '../../common/Common/ThemeProvider';
import { useCheckoutSummary } from './CheckoutSummaryProvider';
import EventNotFound from '../../common/EventNotFound';
import GetEventQuery from './GetEventQuery';
import LocaleProvider from '../../common/LocaleProvider';
import PageLoader from '../Common/PageLoader';
import StateContext from './StateContext';
import WidgetContainer from '../Common/WidgetContainer';
import useCheckoutUrlState from './useCheckoutUrlState';
import useQuery from '../../api/useQuery';

interface EventContextValue {
  event?: Event;
  ticketCategories?: TicketCategory[];
  loading?: boolean;
  refetch?: ObservableQuery<GetEvent, GetEventVariables>['refetch'];
}

export const EventContext = createContext({} as EventContextValue);

export const useEvent = () => useContext(EventContext);

interface EventProviderProps {
  children: ReactNode;
}

const EventProvider = ({ children }: EventProviderProps) => {
  const { form, cleanState } = useContext(StateContext);
  const { embedded, open } = useContext(EmbedContext);
  const { data: checkoutSummaryData } = useCheckoutSummary();
  const [urlState] = useCheckoutUrlState();

  const { data, loading, refetch, error } = useQuery(
    GetEventQuery,
    {
      variables: {
        id: form.event.id,
        invitationCode: form.invitation_code || null,
      },
    },
  );

  const event = data?.event;
  const themeInitialized = useBrandColor(event?.brand_color, !!event);

  /**
   * Only show non-empty ticket categories. If the URL contains a ticket category ID,
   * hide the other ticket categories.
   */
  const ticketCategories = useMemo(() => event?.ticket_categories.filter(
    (ticketCategory) => ticketCategory.tickets_for_sale.length > 0
      && (!urlState.ticketCategoryId || ticketCategory.id === urlState.ticketCategoryId),
  ) || [], [event, urlState.ticketCategoryId]);

  const eventRef = useRef<Event>();

  const [stateInitialized, setStateInitialized] = useState(false);

  // Clean the state (e.g., remove tickets that no longer exist) when the event changes.
  useEffect(() => {
    if (event && event !== eventRef.current) {
      eventRef.current = event;
      cleanState(event);
      setStateInitialized(true);
    }
  }, [event, cleanState]);

  const ready = themeInitialized && stateInitialized;

  // Fetch the latest data when the embed is (re)opened
  useEffect(() => {
    if (embedded && open) {
      refetch();
    }
  }, [embedded, open, refetch]);

  const value = {
    event,
    ticketCategories,
    loading,
    refetch,
  };

  return (
    <EventContext.Provider value={value}>
      <LocaleProvider
        locale={urlState.locale || event?.locale}
        localizeRequests={!!urlState.locale}
        timezone={event?.project.timezone}
      >
        <WidgetContainer showRegisterButton={ready}>
          {(!event || !checkoutSummaryData) && !error && (
            <PageLoader />
          )}
          {event && checkoutSummaryData && !error && ready && children}
          {error && (
            <EventNotFound />
          )}
        </WidgetContainer>
      </LocaleProvider>
    </EventContext.Provider>
  );
};

export default EventProvider;
