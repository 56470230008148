import { useCallback } from 'react';

import { Locale } from '__generated__/graphql';
import { isSupportedLocale } from '../../i18n';
import { isValidLocale } from '../../common/helpers';
import useUrlState from '../../common/useUrlState';

export interface CheckoutUrlState {
  locale?: Locale | null,
  orderId?: string;
  orderToken?: string;
  paymentStatus?: string;
  ticketCategoryId?: string;
  coupon?: string;
  invitationCode?: string;
  charityId?: string;
  sourceUrl?: string;
  _ga?: string; // Google Analytic's Session ID used for cross-domain tracking.
}

const useCheckoutUrlState = () => {
  const parse = useCallback((params: any) => ({
    locale: isValidLocale(params.locale) && isSupportedLocale(params.locale) ? params.locale : undefined,
    orderId: typeof params.orderId === 'string' ? params.orderId : undefined,
    orderToken: typeof params.orderToken === 'string' ? params.orderToken : undefined,
    paymentStatus: typeof params.paymentStatus === 'string' ? params.paymentStatus : undefined,
    ticketCategoryId: typeof params.ticketCategoryId === 'string' ? params.ticketCategoryId : undefined,
    coupon: typeof params.coupon === 'string' ? params.coupon : undefined,
    invitationCode: typeof params.invitationCode === 'string' ? params.invitationCode : undefined,
    charityId: typeof params.charityId === 'string' ? params.charityId : undefined,
    sourceUrl: typeof params.sourceUrl === 'string' ? params.sourceUrl : undefined,
    // eslint-disable-next-line no-underscore-dangle
    _ga: typeof params._ga === 'string' ? params._ga : undefined,
  }), []);

  return useUrlState<CheckoutUrlState>({
    parse,
  });
};

export default useCheckoutUrlState;
