import { Check, ChevronLeft } from 'react-feather';
import { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { GetResaleLayoutQuery as GetResaleLayout } from '__generated__/graphql';

import { EmbedContext } from '../Common/EmbedProvider';
import { TitleBar } from '../Common/Layout';
import { getServerErrors, getStatusCode } from '../../common/helpers';
import { useTracking } from '../../common/Tracking';
import BuyRegistrationForm, { OrderSummary } from './BuyRegistrationForm';
import GetResellableRegistrationQuery from './GetResellableRegistrationQuery';
import PagerContainer from '../Common/PagerContainer';
import TicketCard from './TicketCard';
import Timer from '../Common/Timer';
import UI from '../../common/UI';
import useMediaDevice from '../../common/useMediaDevice';
import usePersistentThrottler from './usePersistentThrottler';
import useQuery from '../../api/useQuery';
import useStickyElement from '../../common/useStickyElement';

type Event = GetResaleLayout['event'];

export interface BuyRegistrationPageProps {
  event: Event;
  registrationId: string;
  resaleToken: string;
}

const BuyRegistrationPage = ({
  event,
  registrationId,
  resaleToken,
}: BuyRegistrationPageProps) => {
  const { t } = useTranslation();
  const { track } = useTracking();
  const { search } = useLocation();

  const [startReselling, setStartReselling] = useState(false);

  const [throttled, setThrottled] = usePersistentThrottler({
    key: 'registration',
  });

  const { data, error, loading } = useQuery(
    GetResellableRegistrationQuery,
    {
      variables: {
        event_id: event.id,
        registration_id: registrationId,
        resale_token: resaleToken,
        start_reselling: startReselling,
      },
      onError: (error) => {
        setThrottled(getStatusCode(error) === 429 || !!getServerErrors(error)?.registration?.too_many_attempts);
      },
      skip: throttled,
    },
  );

  const registration = data?.resellableRegistration;

  const claimed = registration && registration.resale.available && registration.resale.time_left !== null
    && registration.resale.time_left > 0;

  const { embedded } = useContext(EmbedContext);
  const device = useMediaDevice();

  const { anchorRef, elementRef, isSticky, position } = useStickyElement({
    position: 'bottom',
    containerId: 'CheckoutContainer',
    scrollContainerId: embedded && !device.isSmall ? 'PageContainer' : undefined,
  });

  useEffect(() => {
    if (registration) {
      track?.('Resale:RegistrationOpened', {
        registration: registration.id,
        available: registration.resale.available,
      });
    }
  }, [track, registration]);

  return (
    <>
      <UI.Div>
        <TitleBar>
          <UI.FadeIn>
            <UI.Span>
              <UI.Link to={`/${event.id}/resale${search}`} sc={{ small: true }}>
                <UI.Icon>
                  <ChevronLeft />
                </UI.Icon>
                {' '}
                {t('back')}
              </UI.Link>
            </UI.Span>
          </UI.FadeIn>
          <UI.FadeIn>
            {t('buy_ticket')}
          </UI.FadeIn>
        </TitleBar>

        <UI.Card sc={{ hasPanels: true, active: true }}>
          {registration && (
            <>
              <UI.FadeIn>
                <UI.FormGrid>
                  <UI.Legend>
                    {t('selected_ticket')}
                  </UI.Legend>
                  <TicketCard registration={registration} event={event} selected />
                </UI.FormGrid>
              </UI.FadeIn>

              {registration.resale.resellable && registration.resale.available && (
                <>
                  {!claimed && (
                    <>
                      <UI.FadeIn>
                        <UI.FormGrid>
                          <UI.Legend>
                            {t('your_order')}
                          </UI.Legend>
                          <OrderSummary registration={registration} />
                        </UI.FormGrid>
                      </UI.FadeIn>
                      <UI.Div sc={{ padding: 0 }} ref={anchorRef}>
                        <PagerContainer ref={elementRef} isSticky={isSticky} isEmbedded={embedded} {...position}>
                          <UI.Div>
                            <UI.Button
                              onClick={() => setStartReselling(true)}
                              sc={{ block: true, brand: 'secondary', loading }}
                              disabled={loading}
                            >
                              {!loading && t('buy_ticket')}
                              {loading && <UI.Loader sc={{ brand: 'white' }} />}
                            </UI.Button>
                          </UI.Div>
                        </PagerContainer>
                      </UI.Div>
                    </>
                  )}

                  {claimed && (
                    <>
                      <Timer secondsLeft={registration.resale.time_left}>
                        {t('time_left_to_finish_registration')}
                      </Timer>
                      <UI.Div sc={{ padding: 0 }}>
                        <BuyRegistrationForm
                          event={data.event}
                          registration={registration}
                          resaleToken={resaleToken}
                        />
                      </UI.Div>
                    </>
                  )}
                </>
              )}

              {registration.resale.resellable && !registration.resale.available && (
                <UI.Div>
                  <UI.Warning>
                    {t('registration_already_claimed')}
                  </UI.Warning>
                </UI.Div>
              )}
            </>
          )}

          {!throttled && (error || (registration && !registration.resale.resellable)) && (
            <UI.Div>
              <UI.Warning>
                {t('ticket_not_available')}
              </UI.Warning>
            </UI.Div>
          )}

          {throttled && (
            <UI.Div>
              <UI.Error icon={<UI.Loader sc={{ brand: 'error.600' }} />}>
                {t('common:validation.too_many_attempts')}
              </UI.Error>
            </UI.Div>
          )}

          {!data && loading && (
            <UI.Div>
              <UI.PageLoader />
            </UI.Div>
          )}
        </UI.Card>
      </UI.Div>

      <UI.Div sc={{ textAlign: 'center', color: 'gray.600' }}>
        <UI.Icon
          sc={{ background: 'primary', color: 'white', padding: 0.25, mr: 1 }}
          style={{ borderRadius: '100%', fontSize: '0.75em' }}
        >
          <Check strokeWidth={3} />
        </UI.Icon>
        <UI.Span sc={{ mr: 4 }} style={{ fontWeight: 500 }}>
          {t('verified_ticket')}
        </UI.Span>
        <UI.Icon
          sc={{ background: 'primary', color: 'white', padding: 0.25, mr: 1 }}
          style={{ borderRadius: '100%', fontSize: '0.75em' }}
        >
          <Check strokeWidth={3} />
        </UI.Icon>
        <UI.Span style={{ fontWeight: 500 }}>
          {t('100_percent_secure')}
        </UI.Span>
      </UI.Div>
    </>
  );
};

export default BuyRegistrationPage;
