import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Lock, Unlock } from 'react-feather';
import { useTranslation } from 'react-i18next';

import { useEvent } from './EventProvider';
import CheckoutContext from './CheckoutContext';
import UI from '../../common/UI';

const InvitationCodeForm = () => {
  const { t } = useTranslation();
  const { event, loading } = useEvent();
  const { form, setInvitationCode, touch } = useContext(CheckoutContext);

  // State for the input value
  const [value, setValue] = useState(form.invitation_code || '');

  // Code is 'active' if belongs to an active invitation.
  const active = event.invitation_code !== null;

  // While loading the code is neither valid nor invalid
  // Valid flag is for deciding whether to hide the form on submit/blur
  const valid = active && !loading;

  // Invalid flag is used for displaying validation messages
  const invalid = form.invitation_code && !active && !loading;
  const errors = invalid ? { invalid: {} } : null;

  const [showingForm, setShowingForm] = useState(invalid);

  const activate = () => {
    setInvitationCode(value);

    if (!value) {
      setShowingForm(false);
    }
  };

  const onBlur = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (!value || (valid && value === form.invitation_code)) {
      // If the input is empty or unchanged, hide the form on blur
      setShowingForm(false);

      // Also clean up the form state
      setInvitationCode(value);
    }

    touch('invitation_code');
  };

  useEffect(() => {
    if (valid) {
      // The invitation code gave access to a ticket, hide the form.
      setShowingForm(false);
    }
  }, [valid]);

  return (
    <UI.Form onSubmit={() => activate()}>
      {!showingForm && (
        <UI.Div sc={{ textAlign: 'center' }}>
          <UI.Button
            name="invitation_code.toggle"
            sc={{ blank: true, color: 'link', block: true }}
            onClick={() => setShowingForm(true)}
          >
            {active && value && (
              <>
                <UI.Icon>
                  <Unlock />
                </UI.Icon>
                {' '}
                {form.invitation_code}
              </>
            )}
            {!(active && value) && (
              <>
                <UI.Icon>
                  <Lock />
                </UI.Icon>
                {' '}
                {t('enter_invitation_code')}
              </>
            )}
          </UI.Button>
        </UI.Div>
      )}
      {showingForm && (
        <UI.Card sc={{ outline: 'gray.200' }}>
          <UI.InputGroup sc={{ invalid, mb: 0 }}>
            <UI.InputLabel>
              {t('invitation_code')}
            </UI.InputLabel>
            <UI.InputDescription>
              {t('invitation_code_description')}
            </UI.InputDescription>
            <UI.FormGrid sc={{ columns: '1fr 100px', gutter: 0.5, mt: 1 }}>
              <UI.Div>
                <UI.DebouncedInput
                  value={value}
                  name="invitation_code.code"
                  onChange={(event) => setValue(event.target.value)}
                  onBlur={onBlur}
                  autoFocus={!invalid}
                />
              </UI.Div>
              <UI.Div>
                <UI.Button
                  type="submit"
                  name="invitation_code.activate"
                  sc={{ brand: 'secondary', block: true, loading }}
                  disabled={loading || value === (form.invitation_code || '')}
                >
                  {t('activate')}
                </UI.Button>
              </UI.Div>
            </UI.FormGrid>
            <UI.ErrorMessages attribute={t('invitation_code')} errors={errors} />
          </UI.InputGroup>
        </UI.Card>
      )}
    </UI.Form>
  );
};

export default InvitationCodeForm;
