import { useMemo } from 'react';

import {
  AssignmentType, CompleteCheckoutInput, CreateRegistrationInput, ParticipantInput,
} from '__generated__/graphql';
import {
  Event, Product, Session, Ticket, getKeyedSellables, getTicketsForSale, getVisibleProductsForTicket,
} from './helpers';

export interface PersonalisationInfo {
  /** The list of registrations that may be personalised, a subset of form.registrations.create */
  personalisableRegistrations: CreateRegistrationInput[];
  /** The registration that is currently being personalised */
  activeRegistration?: CreateRegistrationInput;
  /** The index of the registration that is currently being personalised */
  activeIndex: number;
  /** The index of the previous personalisable registration (compared to activeIndex) */
  prevIndex: number;
  /** The index of the next personalisable registration (compared to activeIndex) */
  nextIndex: number;
  /** A list of booleans indicating which of the personalisable registrations are skipped */
  skips: boolean[];
  /** A list of indices indicating which registrations in the form are personalisable (skipped or not) */
  personalisableFormIndices: number[];
  /** A list of indices indicating which registrations in the form are personalisable and NOT skipped */
  personalisedFormIndices: number[];
  /** The index of the registration in the form that is currently being personalised */
  activeFormIndex: number;
  /** The details of the person placing the order */
  buyer: ParticipantInput;
  /** The registrations that are assigned to the buyer */
  personalRegistrations: CreateRegistrationInput[];
}

interface PersonalisationInfoProps {
  form: CompleteCheckoutInput;
  event: Event;
  session: Session;
}

const usePersonalisationInfo = ({ form, event, session }: PersonalisationInfoProps) => (
  useMemo(
    () => getPersonalisationInfo(
      form.registrations.create, form.participant, session.activeRegistration, event,
    ),
    [form.registrations.create, form.participant, session.activeRegistration, event],
  )
);

const getPersonalisationInfo = (
  allRegistrations: CreateRegistrationInput[],
  buyer: ParticipantInput,
  activeRegistrationIndex: number,
  event: Event,
): PersonalisationInfo => {
  /** Indices of registrations in allRegistrations that are personalisable */
  const personalisableFormIndices = getPersonalisableRegistrationIndices(allRegistrations, event);
  /** Personalisable registrations */
  const personalisableRegistrations = personalisableFormIndices.map((index) => allRegistrations[index]);

  const tickets = getTicketsForSale(event);

  const { nextIndex, prevIndex, skips } = getPaginationInfo(
    personalisableRegistrations,
    activeRegistrationIndex,
    tickets,
    event.products_for_sale,
  );

  const personalisedFormIndices = personalisableFormIndices.filter((formIndex) => {
    const isSkipped = () => {
      const personalisableRegistrationIndex = personalisableFormIndices.findIndex((i) => i === formIndex);

      if (personalisableRegistrationIndex > -1) {
        return skips[personalisableRegistrationIndex];
      }

      return false;
    };

    return personalisableFormIndices.includes(formIndex) && !isSkipped();
  });

  /**
   * Get the registrations that are assigned - based on e-mail and name - to the buyer.
   */
  const personalRegistrations = allRegistrations.filter(({ participant }) => {
    const assignee = participant;

    if (assignee && buyer) {
      if (assignee.email && assignee.first_name && assignee.last_name) {
        return assignee.email.toLowerCase() === buyer.email?.toLowerCase()
        && assignee.first_name.toLowerCase() === buyer.first_name?.toLowerCase()
        && assignee.last_name.toLowerCase() === buyer.last_name?.toLowerCase();
      }
    }

    return false;
  });

  return {
    personalisableRegistrations,
    activeRegistration: personalisableRegistrations[activeRegistrationIndex],
    activeIndex: activeRegistrationIndex,
    nextIndex,
    prevIndex,
    skips,
    personalisableFormIndices,
    personalisedFormIndices,
    activeFormIndex: personalisableFormIndices[activeRegistrationIndex],
    personalRegistrations,
    buyer,
  };
};

/**
 * Get the indices of registrations that are personalisable: registrations that are assignable,
 * that have fields, or that have products.
 */
const getPersonalisableRegistrationIndices = (
  registrations: CreateRegistrationInput[],
  event: Event,
) => registrations.reduce<number[]>((result, registration, index) => {
  const tickets = getTicketsForSale(event);
  const ticket = getKeyedSellables(tickets)[registration.purchase.promotion.id];

  // Check that the ticket exists, because it might disappear when an activated invitation code is unset
  if (ticket) {
    const products = getVisibleProductsForTicket(ticket, event.products_for_sale);

    if (ticket.assignment_type !== AssignmentType.afterward || products.length > 0) {
      return [...result, index];
    }
  }

  return result;
}, []);

/** Exported for testing purposes only */
export const getPersonalisableRegistrationIndicesForTesting = getPersonalisableRegistrationIndices;

/**
 * Returns the previous and next registration index taking into account potential skips.
 * Registrations of the same type are skipped when the first is not personalised.
 */
const getPaginationInfo = (
  registrations: CreateRegistrationInput[],
  activeIndex: number,
  tickets: Ticket[],
  products: Product[],
) => {
  const skips = getSkips(registrations, tickets, products);
  const nextIndex = activeIndex + 1 + [...skips, false].slice(activeIndex + 1).findIndex((skip) => !skip);
  const prevIndex = activeIndex - 1 - skips.slice(0, activeIndex).reverse().findIndex((skip) => !skip);

  return {
    skips,
    nextIndex,
    prevIndex,
  };
};

/** Exported for testing purposes only */
export const getPaginationInfoForTesting = getPaginationInfo;

const getSkips = (registrations: CreateRegistrationInput[], tickets: Ticket[], products: Product[]) => {
  const keyedTickets = getKeyedSellables(tickets);
  const keyedProducts = getKeyedSellables(products);

  /**
   * A registration is empty (skippable) if:
   * 1. the registration is unassigned,
   * 2. the registration has no custom fields,
   * 3. the registration has no upgrades (except ticket fees).
   */
  const isEmptyRegistration = (registration: CreateRegistrationInput) => {
    const ticket = keyedTickets[registration.purchase.promotion.id];

    /** Indicates whether there are (non ticket fee) products that are required with this ticket */
    const hasRequiredProducts = () => (
      // Ticket check because ticket might not exist anymore
      ticket && getVisibleProductsForTicket(ticket, products).filter(
        (product) => product.min_per_ticket > 0,
      ).length > 0
    );

    /** Indicates whether the participant has any (non ticket fee) upgrades */
    const hasNonTicketFeeUpgrades = () => registration.upgrades.filter((upgrade) => (
      // Optional chaining because product might not exist anymore
      !keyedProducts[upgrade.purchase.promotion.id]?.is_ticket_fee
    )).length > 0;

    return !registration.participant
      && (registration.fields?.length || 0) === 0
      && !hasRequiredProducts()
      && !hasNonTicketFeeUpgrades();
  };

  const isSimilarRegistration = (registration: CreateRegistrationInput, other: CreateRegistrationInput) => (
    registration.purchase.promotion.id === other.purchase.promotion.id
      && registration.time_slot?.id === other.time_slot?.id
  );

  const containsSimilarAssignedRegistration = (
    registration: CreateRegistrationInput, registrations: CreateRegistrationInput[],
  ) => (
    registrations.filter(
      (other) => isSimilarRegistration(registration, other)
        // Because registrations are assigned by default,
        // consider registration without participant details as unassigned.
        && (other.participant?.email || other.participant?.first_name || other.participant?.last_name),
    ).length > 0
  );

  const skips = [false];
  let prototype: CreateRegistrationInput;

  registrations.forEach((registration, index) => {
    if (index > 0) {
      if (!skips[index - 1]) {
        const prevRegistration = registrations[index - 1];

        if (isEmptyRegistration(prevRegistration)) {
          prototype = prevRegistration;
        } else {
          prototype = null;
        }
      }

      if (prototype) {
        skips[index] = isSimilarRegistration(registration, prototype)
          && !containsSimilarAssignedRegistration(registration, registrations.slice(index));
      } else {
        skips[index] = false;
      }
    }
  });

  return skips;
};

export default usePersonalisationInfo;
