import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import ResizeObserver from 'resize-observer-polyfill';
import debounce from 'lodash/debounce';

interface UseStickyElementProps {
  /**
   * Indicates whether the element should stick to the top or the bottom of the viewport.
   */
  position?: 'top' | 'bottom';
  offset?: number;
  containerId?: string;
  floatingElementHeight?: number;
  scrollContainerId?: string;
}

interface Position {
  left: number;
  right: number;
  top: number | 'auto';
  bottom: number | 'auto';
}

/**
 * Makes an element stick to the top or bottom of the viewport as soon as the user scrolls past it.
 */
const useStickyElement = ({
  position = 'top', offset = 0, containerId, floatingElementHeight, scrollContainerId,
}: UseStickyElementProps) => {
  /**
   * Ref for the direct parent of the sticky element.
  */
  const anchorRef = useRef<HTMLDivElement>();
  const anchorHeightRef = useRef<number>();

  /**
   * Ref for the sticky element.
   */
  const elementRef = useRef<HTMLDivElement>();

  const defaultPosition: Position = useMemo(() => ({
    left: 0,
    right: 0,
    top: position === 'top' ? offset : 'auto',
    bottom: position === 'bottom' ? offset : 'auto',
  }), [position, offset]);

  /**
   * Indicates whether the user has scrolled past the element.
   */
  const [state, setState] = useState({
    isSticky: false,
    position: defaultPosition,
  });

  const setSticky = (isSticky: boolean) => {
    setState((state) => {
      if (isSticky !== state.isSticky) {
        return {
          ...state,
          isSticky,
        };
      }

      return state;
    });
  };

  const setPosition = useCallback((position: Position) => {
    setState((state) => {
      const changed = position.left !== state.position.left
        || position.right !== state.position.right
        || position.top !== state.position.top
        || position.bottom !== state.position.bottom;

      if (changed) {
        return {
          ...state,
          position,
        };
      }

      return state;
    });
  }, []);

  /**
   * Sets the height of the parent element to prevent layout shift.
   */
  const setAnchorHeight = useCallback(() => {
    const anchor = anchorRef.current;

    if (anchor) {
      anchor.style.height = null;

      if (anchor.offsetHeight > 0) {
        anchor.style.height = `${anchor.offsetHeight}px`;
        anchorHeightRef.current = anchor.offsetHeight;
      }
    }
  }, [anchorRef, anchorHeightRef]);

  useEffect(() => {
    if (!state.isSticky) {
      setAnchorHeight();
    }
  }, [state.isSticky, setAnchorHeight]);

  // Keeps the parent container the same height as the sticky element, to prevent layout shift.
  useEffect(() => {
    if (!floatingElementHeight) {
      const element = elementRef.current;
      const anchor = anchorRef.current;

      if (element && anchor) {
        const observer = new ResizeObserver(debounce(([entry]: ResizeObserverEntry[]) => {
          anchor.style.height = `${entry.contentRect.height}px`;
        }));

        observer.observe(element);

        return () => observer.unobserve(element);
      }
    }

    return undefined;
  }, [floatingElementHeight]);

  /**
   * The height of the element before it starts to float. This value is used to prevent layout shift.
   */
  const originalHeight = anchorHeightRef.current || anchorRef.current?.clientHeight || 0;

  /**
   * The container within which the floating element should remain visible.
   */
  const container = (containerId && document.getElementById(containerId)) || document.body;

  /**
   * If given, the scrollable parent element.
   */
  const scrollContainer = scrollContainerId && document.getElementById(scrollContainerId);

  /**
   * Handles the 'isSticky' state on scroll and/or rerender.
   */
  useEffect(() => {
    const handleScroll = () => {
      if (!originalHeight) {
        setAnchorHeight();
      }

      const element = anchorRef.current;

      if (element) {
        const elementPosition = element.getBoundingClientRect();
        const containerPosition = container.getBoundingClientRect();
        const heightCorrection = floatingElementHeight ? floatingElementHeight - originalHeight : 0;

        if (position === 'top') {
          const top = typeof state.position.top === 'number' ? state.position.top : offset;
          const containerInView = containerPosition.bottom - originalHeight - heightCorrection > top;
          const anchorInView = elementPosition.top - heightCorrection > top;

          setSticky(containerInView && !anchorInView);
        } else if (position === 'bottom') {
          const bottom = typeof state.position.bottom === 'number' ? state.position.bottom : offset;
          const containerInView = (
            containerPosition.top + originalHeight + heightCorrection < window.innerHeight - bottom
          );
          const anchorInView = elementPosition.bottom + heightCorrection < window.innerHeight - bottom;

          setSticky(containerInView && !anchorInView);
        }
      }
    };

    handleScroll();

    (scrollContainer || document).addEventListener('scroll', handleScroll);

    // Handle changes to container height.
    // Debounce to avoid "ResizeObserver loop completed with undelivered notifications" error
    const observer = new ResizeObserver(debounce(handleScroll));
    const innerScrollContainer = scrollContainer?.children[0];
    observer.observe(innerScrollContainer || document.body);

    return () => {
      (scrollContainer || document).removeEventListener('scroll', handleScroll);
      observer.unobserve(innerScrollContainer || document.body);
    };
  }, [
    container, floatingElementHeight, offset, originalHeight, position, scrollContainer, setAnchorHeight,
    state.position.bottom, state.position.top,
  ]);

  /**
   * Handles the 'position' state on resize.
   */
  useEffect(() => {
    if (scrollContainer) {
      const handleResize = () => {
        const { left, right, top, bottom } = scrollContainer.getBoundingClientRect();

        setPosition({
          left,
          right: window.innerWidth - right,
          top: position === 'top' ? top + offset : 'auto',
          bottom: position === 'bottom' ? window.innerHeight - bottom + offset : 'auto',
        });
      };

      handleResize();

      // Debounce to avoid "ResizeObserver loop completed with undelivered notifications" error
      const observer = new ResizeObserver(debounce(handleResize));

      observer.observe(scrollContainer);

      return () => observer.unobserve(scrollContainer);
    }

    // No scroll container (anymore), reset position to default position
    setPosition(defaultPosition);

    return undefined;
  }, [scrollContainer, offset, position, setPosition, defaultPosition]);

  /**
   * This method should be called by the consumer of this hook whenever something
   * other than a scroll event happens that influences the sticky state.
   */
  const reset = useCallback(() => {
    setSticky(false);
    setAnchorHeight();
  }, [setAnchorHeight]);

  return {
    ...state,
    anchorRef,
    elementRef,
    reset,
  };
};

export default useStickyElement;
