import { useContext } from 'react';

import GetCharityQuery from './GetCharityQuery';
import StateContext from './StateContext';
import useCheckoutUrlState from './useCheckoutUrlState';
import useQuery from '../../api/useQuery';

const usePreselectedCharity = () => {
  const { setCharity, rememberCharity } = useContext(StateContext);
  const [{ charityId }, setUrlState] = useCheckoutUrlState();

  const { data } = useQuery(GetCharityQuery, {
    variables: {
      id: charityId,
    },
    skip: !charityId,
    onCompleted: ({ charity }) => {
      setCharity(charity);
      rememberCharity(charity);
    },
    onError: () => {
      setCharity(null);
      setUrlState(({ charityId, ...params }) => params);
    },
  });

  return {
    preselectedCharity: data?.charity,
    hasPreselectedCharity: !!charityId,
  };
};

export default usePreselectedCharity;
